import map from 'lodash/map'
import React from 'react'
import tw from 'twin.macro'
import { usePageContext } from '../context/page-context'
import { StyleType } from '../utils/prop-types'
import Link from './link'

const LanguageToggle = ({ style }) => {
  const { originalPath, locales, locale } = usePageContext()

  const options = map(locales, (l) => ({
    label: l.label,
    fullLabel: l.fullName,
    value: l.lang,
    link: `/${l.lang}${originalPath}`,
  }))

  return (
    <div css={[tw`flex flex-row justify-start`, style]}>
      {options.map((option) => (
        <Link
          to={option.link}
          localized={false}
          key={option.label}
          style={[
            tw`border border-primary-500 border-opacity-77 h-10 px-4 flex items-center`,
            locale === option.value && tw`bg-primary-500`,
          ]}
        >
          <span
            css={[
              tw`text-mobile-xs text-primary-500 font-medium whitespace-nowrap`,
              locale === option.value && tw`text-white`,
            ]}
          >
            {option.label}
          </span>
        </Link>
      ))}
    </div>
  )
}

LanguageToggle.propTypes = {
  style: StyleType,
}

export default LanguageToggle
